
export function isCurrentStatusAfter(store, currentStatus, statusToTest) {
    if (store.getters.getAffairStatusList[currentStatus] == null || store.getters.getAffairStatusList[statusToTest] == null) return false;
    return store.getters.getAffairStatusList[currentStatus].order > store.getters.getAffairStatusList[statusToTest].order;
}

export function isCurrentStatusAfterOrSame(store, currentStatus, statusToTest) {
    if (store.getters.getAffairStatusList[currentStatus] == null || store.getters.getAffairStatusList[statusToTest] == null) return false;
    return store.getters.getAffairStatusList[currentStatus].order >= store.getters.getAffairStatusList[statusToTest].order;
}

export function isCurrentStatusBefore(store, currentStatus, statusToTest) {
    if (store.getters.getAffairStatusList[currentStatus] == null || store.getters.getAffairStatusList[statusToTest] == null) return false;
    return store.getters.getAffairStatusList[currentStatus].order < store.getters.getAffairStatusList[statusToTest].order;
}

export function isCurrentStatusBeforeOrSame(store, currentStatus, statusToTest) {
    if (store.getters.getAffairStatusList[currentStatus] == null || store.getters.getAffairStatusList[statusToTest] == null) return false;
    return store.getters.getAffairStatusList[currentStatus].order <= store.getters.getAffairStatusList[statusToTest].order;
}

export function isCurrentStatusSame(store, currentStatus, statusToTest) {
    if (store.getters.getAffairStatusList[currentStatus] == null || store.getters.getAffairStatusList[statusToTest] == null) return false;
    return store.getters.getAffairStatusList[currentStatus].order === store.getters.getAffairStatusList[statusToTest].order;
}